import React from "react";
import PropTypes from "prop-types";

/* Components */
import { CompoundLabel } from "./Label";

const CallToAction = ({ cta }) => {
  if (!cta) return null;

  return <CompoundLabel {...cta} />;
};

CallToAction.propTypes = {
  cta: PropTypes.object
};

export default CallToAction;
